import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  CheckboxGroup,
  ContentLoader,
  Divider,
  Stack,
  Text,
} from "@vygruppen/spor-react";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { LineFilter, StationFilter } from "../../shared/Types";
import useLines from "../../shared/hooks/useLines";
import DelayedLoading from "../../shared/components/Loading/DelayedLoading";
import FailureAlert from "../../shared/components/Alert/FailureAlert";
import StationSelectAccordion from "./StationSelectAccordion";
import { getCurrentCountryCode } from "../../environment";

interface Props {
  lineFilters: LineFilter[];
  setLineFilters: Dispatch<SetStateAction<LineFilter[]>>;
  stationFilters: StationFilter[];
  setStationFilters: Dispatch<SetStateAction<StationFilter[]>>;
}

export const Step2Content = ({
  lineFilters,
  setLineFilters,
  stationFilters,
  setStationFilters,
}: Props) => {
  const { t } = useTranslation();
  const { lines, getLineStatus } = useLines();
  const uniqueLines = lines.filter(
    (line, index, self) =>
      self.findIndex(l => l.name + l.publicCode === line.name + line.publicCode) === index
  );

  const inSweden = getCurrentCountryCode() === "SE";

  const getCheckedNum = () => {
    const checkedLineIds = lineFilters
      .filter(lineFilter => lineFilter.filterValue.id !== "ALL")
      .map(lineFilter => lineFilter.filterValue.name + lineFilter.filterValue.publicCode);

    return new Set(checkedLineIds).size;
  };

  const handleChange = (lineIds: (string | number)[]) => {
    const lineFilters: LineFilter[] = lines
      .filter(line => lineIds.includes(line.publicCode))
      .map(line => ({
        filterValue: line,
      }));

    const allChecked = lines.every(line => lineIds.some(lineId => lineId === line.id));

    if (allChecked) {
      lineFilters.push({
        filterValue: {
          id: "ALL",
          publicCode: "ALL",
          name: "ALL",
        },
      });
    }
    setLineFilters(lineFilters);
  };

  const groupedLabels = () => {
    return (
      <AccordionPanel>
        <Box mb={1}>{renderSelectAllButton()}</Box>
        <CheckboxGroup
          direction="column"
          onChange={lineIds => handleChange(lineIds)}
          value={lineFilters.map(lineFilter => lineFilter.filterValue.publicCode)}
        >
          {uniqueLines &&
            uniqueLines.map(line => (
              <Checkbox key={line.id} value={line.publicCode}>
                {line.publicCode} {line.name}
              </Checkbox>
            ))}
        </CheckboxGroup>
      </AccordionPanel>
    );
  };

  const renderSelectAllButton = () => {
    const allChecked = lines.every(line =>
      lineFilters.some(filter => filter.filterValue.id === line.id)
    );

    const handleSelectAll = (selectAll: boolean) => {
      if (selectAll) {
        const lineFilters: LineFilter[] = lines.map(line => ({
          filterValue: line,
        }));
        lineFilters.push({
          filterValue: {
            id: "ALL",
            publicCode: "ALL",
            name: "ALL",
          },
        });
        setLineFilters(lineFilters);
      } else {
        setLineFilters([]);
      }
    };

    return (
      <Checkbox
        isChecked={allChecked}
        onChange={e => handleSelectAll(e.target.checked)}
        value="ALL"
      >
        {t("actions.selectAll")}
      </Checkbox>
    );
  };

  const getContent = () => {
    switch (getLineStatus.status) {
      case "success":
        return (
          <Accordion variant="ghost" allowToggle defaultIndex={inSweden ? undefined : 0}>
            <Stack spacing={1} divider={<Divider />}>
              <AccordionItem>
                <AccordionButton fontWeight="bold">
                  {t("LINE")} ({getCheckedNum()})
                  <AccordionIcon />
                </AccordionButton>
                {groupedLabels()}
              </AccordionItem>

              {inSweden && (
                <StationSelectAccordion
                  stationFilters={stationFilters}
                  setStationFilters={setStationFilters}
                />
              )}
            </Stack>
            <Divider borderBottomWidth="1px" />
          </Accordion>
        );
      case "notAsked":
      case "loading":
        return (
          <DelayedLoading delay={600}>
            <ContentLoader />
          </DelayedLoading>
        );
      case "failure":
        return (
          <FailureAlert variant="error" errorMessage={t("errorMessages.errorFetchingLines")} />
        );
    }
  };

  return (
    <>
      <Text variant="sm" fontWeight="400" marginTop="4vh" marginBottom="2vh">
        {t("subscriptionPreference.newSubscriptionFilters")}{" "}
      </Text>
      {getContent()}
    </>
  );
};
